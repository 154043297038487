<template>
  <VRow
    v-if="!isMobile"
    class="mb-5"
  >
    <VCol
      sm="12"
      md="10"
    >
      <VRow
        v-if="task"
      >
        <VCol
          cols="12"
          class="pb-2"
        >
          <h1
            data-test="level-task-name"
            class="tt-text-headline-1"
          >
            {{ task.name }}
          </h1>
        </VCol>
        <VCol
          cols="12"
          class="pt-0"
        >
          <div class="d-flex">
            <ChipDuration
              class="mr-2"
              :duration="task.durationMin"
              data-test="level-task-duration"
            />
            <ChipBonus
              class="mr-2"
              :current="task.bonus"
              :max="task.maxBonus"
              data-test="level-task-bonus"
            />
            <ChipStatus
              :status="task.isRequired ? $options.REQUIRED : null"
              data-test="level-task-status"
            />
          </div>
        </VCol>
      </VRow>
      <VRow v-if="task">
        <VCol
          v-for="(content) in task.content"
          :id="`item-${content.id}`"
          :key="content.id"
          cols="12"
          :md="$options.TASK_COLS"
          sm="12"
        >
          <HTMLItem
            v-if="content.type === $options.ITEM_TYPES.HTML"
            :meta="content.meta"
            data-test="level-html-item"
          />

          <CardWrapper
            v-else-if="content.type === $options.ITEM_TYPES.DOCUMENT"
            flat
            class="fill-height pa-5"
            corner-cut
            shadow="never"
          >
            <div
              v-if="content.itemStatus"
              class="pb-2"
            >
              <ChipStatus :status="content.itemStatus" />
            </div>
            <DocumentItem
              :meta="content.meta"
              :result="!!content.result"
              :can-be-skipped="content.canBeSkipped"
              :data-test="`level-document-item-${content.id}`"
              @submit="submit(true, content)"
            />
          </CardWrapper>

          <CardWrapper
            v-else-if="content.type === $options.ITEM_TYPES.CONTACT"
            class="pa-5"
          >
            <ContactsItem
              :data-test="`level-contacts-item-${content.id}`"
              :meta="content.meta"
            />
          </CardWrapper>

          <CardWrapper
            v-else-if="content.type === $options.ITEM_TYPES.RATE"
            class="pa-5"
            :status="content.itemStatus"
          >
            <RateItem
              :meta="content.meta"
              :result="content.result"
              :disabled="loading===content.id"
              :loading="loading===content.id"
              :can-be-skipped="content.canBeSkipped"
              :is-skipped="content.isSkipped"
              :data-test="`level-rate-item-${content.id}`"
              @submit="submit($event, content)"
              @skip="skip(content.id)"
            />
          </CardWrapper>

          <CardWrapper
            v-else-if="content.type === $options.ITEM_TYPES.ADD_PHOTO"
            class="pa-5"
            :status="content.itemStatus"
          >
            <ImageFormItem
              :meta="content.meta"
              :is-skipped="content.isSkipped"
              :error-messages="getErrorMessages(content.id)"
              :is-item-completed="content.isCompleted"
              :can-be-skipped="content.canBeSkipped"
              :task-completed="task.isCompleted"
              :data-test="`level-image-form-item-${content.id}`"
              @submit="({payload,callback}) => submit(payload, content,callback)"
              @skip="skip(content.id)"
            />
          </CardWrapper>

          <CardWrapper
            v-else-if="content.type === $options.ITEM_TYPES.YES_NO"
            :status="content.itemStatus"
            class="pa-5"
          >
            <YesOrNoItem
              :meta="content.meta"
              :result="content.result"
              :can-be-skipped="content.canBeSkipped"
              :is-skipped="content.isSkipped"
              :disabled="loading===content.id"
              :loading="loading===content.id"
              :data-test="`level-yes-or-no-item-${content.id}`"
              @submit="submit($event, content)"
              @skip="skip(content.id)"
            />
          </CardWrapper>

          <CardWrapper
            v-else-if="content.type === $options.ITEM_TYPES.SELECT_ONE"
            class="pa-5"
            :status="content.itemStatus"
          >
            <QuestionItem
              :meta="content.meta"
              :result="content.result"
              :can-be-skipped="content.canBeSkipped"
              :is-skipped="content.isSkipped"
              :disabled="loading===content.id"
              :loading="loading===content.id"
              :data-test="`level-question-item-${content.id}`"
              @submit="submit($event, content)"
              @skip="skip(content.id)"
            />
          </CardWrapper>

          <CardWrapper
            v-else-if="content.type === $options.ITEM_TYPES.SELECT_MANY"
            class="pa-5"
            :status="content.itemStatus"
          >
            <QuestionMultipleItem
              :meta="content.meta"
              :result="content.result"
              :can-be-skipped="content.canBeSkipped"
              :is-skipped="content.isSkipped"
              :disabled="loading===content.id"
              :loading="loading===content.id"
              :data-test="`level-question-multiple-select-many-item-${content.id}`"
              @submit="submit($event, content)"
              @skip="skip(content.id)"
            />
          </CardWrapper>

          <CardWrapper
            v-else-if="content.type === $options.ITEM_TYPES.VOTE"
            class="pa-5"
            :status="content.itemStatus"
          >
            <QuestionMultipleItem
              v-if="content.meta.is_multi"
              is-vote
              :meta="content.meta"
              :result="content.result"
              :can-be-skipped="content.canBeSkipped"
              :is-skipped="content.isSkipped"
              :disabled="loading===content.id"
              :loading="loading===content.id"
              :data-test="`level-question-multiple-vote-item-${content.id}`"
              @submit="submit($event, content)"
              @skip="skip(content.id)"
            />
            <QuestionItem
              v-else
              is-vote
              :meta="content.meta"
              :result="content.result"
              :can-be-skipped="content.canBeSkipped"
              :is-skipped="content.isSkipped"
              :disabled="loading===content.id"
              :loading="loading===content.id"
              :data-test="`level-question-vote-item-${content.id}`"
              @submit="submit($event, content)"
              @skip="skip(content.id)"
            />
          </CardWrapper>

          <CardWrapper
            v-else-if="
              (content.type === $options.ITEM_TYPES.ONE_WORD) || (content.type === $options.ITEM_TYPES.QR_CODE)
            "
            class="pa-5"
            :status="content.itemStatus"
          >
            <WordItem
              :is-q-r="content.type === $options.ITEM_TYPES.QR_CODE"
              :meta="content.meta"
              :result="content.result"
              :can-be-skipped="content.canBeSkipped"
              :is-skipped="content.isSkipped"
              :disabled="loading===content.id"
              :loading="loading===content.id"
              :data-test="`level-word-item-${content.id}`"
              @submit="submit($event, content)"
              @skip="skip(content.id)"
            />
          </CardWrapper>

          <MapItem
            v-else-if="content.type === $options.ITEM_TYPES.MAP"
            :meta="content.meta"
            :result="content.result"
            :can-be-skipped="content.canBeSkipped"
            :is-skipped="content.isSkipped"
            :disabled="loading===content.id"
            :loading="loading===content.id"
            :data-test="`level-map-item-${content.id}`"
            @submit="submit($event, content)"
            @skip="skip(content.id)"
          />

          <VideoItem
            v-else-if="content.type === $options.ITEM_TYPES.VIDEO"
            :meta="content.meta"
            :result="!!content.result"
            :data-test="`level-video-item-${content.id}`"
            @submit="submit(true, content)"
          />

          <CardWrapper
            v-else-if="content.type === $options.ITEM_TYPES.ENTER_STRING"
            class="pa-5"
            :status="content.itemStatus"
          >
            <TextAnswerItem
              :meta="content.meta"
              :result="content.result"
              :can-be-skipped="content.canBeSkipped"
              :is-skipped="content.isSkipped"
              :disabled="loading===content.id"
              :loading="loading===content.id"
              :task-completed="task.isCompleted"
              :data-test="`level-text-answer-item-${content.id}`"
              @submit="submit($event, content)"
              @skip="skip(content.id)"
            />
          </CardWrapper>
          <div
            v-else-if="content.type === $options.ITEM_TYPES.IMAGE"
          >
            <ImageTaskItem
              :data-test="`level-image-task-item-${content.id}`"
              :meta="content.meta"
            />
          </div>
          <CardWrapper
            v-else-if="content.type === $options.ITEM_TYPES.OFFER"
            class="pa-5"
          >
            <OfferItem
              :meta="content.meta"
              :data-test="`level-offer-item-${content.id}`"
            />
          </CardWrapper>
        </VCol>
      </VRow>
      <VRow v-if="task && !task.isCompleted">
        <VCol
          :cols="12"
          :md="$options.TASK_COLS"
        >
          <TTBtn
            block
            large
            :loading="completeBtnLoading"
            class="mb-5"
            :disabled="!canBeCompleted"
            data-test-label="level-view-btn-task-complete"
            @click="completeTask"
          >
            {{ $t('common.complete') }} {{ $t('common.task') }}
          </TTBtn>
        </VCol>
      </VRow>
    </VCol>
    <Portal to="tt-view-right">
      <AskQuestion
        :task-id="taskId"
        @close="$router.push({name : Names.R_APP_LEVELS})"
      />
    </Portal>
  </VRow>
  <div v-else>
    <AppBarMobile
      v-if="task"
      height="auto"
    >
      <VContainer class="px-0">
        <VRow align="center">
          <VCol cols="2">
            <BackButton />
          </VCol>
          <VCol
            class="text-center"
          >
            <h1
              data-test="level-task-name"
              class="tt-text-title-1 tt-light-mono-4--text"
            >
              {{ task.name }}
            </h1>
          </VCol>
          <VCol cols="2">
            <TTBtn
              data-test-label="show-task-panel-button"
              icon
              @click="handleClickTaskPanel"
            >
              <VIcon color="tt-light-mono-4">
                fas fa-route
              </VIcon>
            </TTBtn>
          </VCol>
        </VRow>
        <VRow
          v-if="task"
          dense
        >
          <VCol cols="auto">
            <ChipDuration
              class="mr-2"
              :duration="task.durationMin"
              data-test="level-task-duration"
            />
          </VCol>
          <VCol cols="auto">
            <ChipBonus
              class="mr-2"
              :current="task.bonus"
              :max="task.maxBonus"
              data-test="level-task-bonus"
            />
          </VCol>
          <VCol cols="auto">
            <ChipStatus
              :status="task.isRequired ? $options.REQUIRED : null"
              data-test="level-task-status"
            />
          </VCol>
        </VRow>
      </VContainer>
    </AppBarMobile>
    <VContainer>
      <VRow>
        <VCol
          cols="12"
        >
          <VRow v-if="task">
            <VCol
              v-for="(content) in task.content"
              :id="`item-${content.id}`"
              :key="content.id"
              cols="12"
              :md="$options.TASK_COLS"
              sm="12"
            >
              <HTMLItem
                v-if="content.type === $options.ITEM_TYPES.HTML"
                :meta="content.meta"
                data-test="level-html-item"
              />

              <CardWrapper
                v-else-if="content.type === $options.ITEM_TYPES.DOCUMENT"
                flat
                class="fill-height pa-5"
                corner-cut
                shadow="never"
              >
                <div
                  v-if="content.itemStatus"
                  class="pb-2"
                >
                  <ChipStatus :status="content.itemStatus" />
                </div>
                <DocumentItem
                  :meta="content.meta"
                  :result="!!content.result"
                  :can-be-skipped="content.canBeSkipped"
                  :data-test="`level-document-item-${content.id}`"
                  @submit="submit(true, content)"
                />
              </CardWrapper>

              <CardWrapper
                v-else-if="content.type === $options.ITEM_TYPES.CONTACT"
                class="pa-5"
              >
                <ContactsItem
                  :data-test="`level-contacts-item-${content.id}`"
                  :meta="content.meta"
                />
              </CardWrapper>

              <CardWrapper
                v-else-if="content.type === $options.ITEM_TYPES.RATE"
                class="pa-5"
                :status="content.itemStatus"
              >
                <RateItem
                  :meta="content.meta"
                  :result="content.result"
                  :disabled="loading===content.id"
                  :loading="loading===content.id"
                  :can-be-skipped="content.canBeSkipped"
                  :is-skipped="content.isSkipped"
                  :data-test="`level-rate-item-${content.id}`"
                  @submit="submit($event, content)"
                  @skip="skip(content.id)"
                />
              </CardWrapper>

              <CardWrapper
                v-else-if="content.type === $options.ITEM_TYPES.ADD_PHOTO"
                class="pa-5"
                :status="content.itemStatus"
              >
                <ImageFormItem
                  :meta="content.meta"
                  :is-skipped="content.isSkipped"
                  :error-messages="getErrorMessages(content.id)"
                  :is-item-completed="content.isCompleted"
                  :can-be-skipped="content.canBeSkipped"
                  :task-completed="task.isCompleted"
                  :data-test="`level-image-form-item-${content.id}`"
                  @submit="({payload,callback}) => submit(payload, content,callback)"
                  @skip="skip(content.id)"
                />
              </CardWrapper>

              <CardWrapper
                v-else-if="content.type === $options.ITEM_TYPES.YES_NO"
                :status="content.itemStatus"
                class="pa-5"
              >
                <YesOrNoItem
                  :meta="content.meta"
                  :result="content.result"
                  :can-be-skipped="content.canBeSkipped"
                  :is-skipped="content.isSkipped"
                  :disabled="loading===content.id"
                  :loading="loading===content.id"
                  :data-test="`level-yes-or-no-item-${content.id}`"
                  @submit="submit($event, content)"
                  @skip="skip(content.id)"
                />
              </CardWrapper>

              <CardWrapper
                v-else-if="content.type === $options.ITEM_TYPES.SELECT_ONE"
                class="pa-5"
                :status="content.itemStatus"
              >
                <QuestionItem
                  :meta="content.meta"
                  :result="content.result"
                  :can-be-skipped="content.canBeSkipped"
                  :is-skipped="content.isSkipped"
                  :disabled="loading===content.id"
                  :loading="loading===content.id"
                  :data-test="`level-question-item-${content.id}`"
                  @submit="submit($event, content)"
                  @skip="skip(content.id)"
                />
              </CardWrapper>

              <CardWrapper
                v-else-if="content.type === $options.ITEM_TYPES.SELECT_MANY"
                class="pa-5"
                :status="content.itemStatus"
              >
                <QuestionMultipleItem
                  :meta="content.meta"
                  :result="content.result"
                  :can-be-skipped="content.canBeSkipped"
                  :is-skipped="content.isSkipped"
                  :disabled="loading===content.id"
                  :loading="loading===content.id"
                  :data-test="`level-question-multiple-select-many-item-${content.id}`"
                  @submit="submit($event, content)"
                  @skip="skip(content.id)"
                />
              </CardWrapper>

              <CardWrapper
                v-else-if="content.type === $options.ITEM_TYPES.VOTE"
                class="pa-5"
                :status="content.itemStatus"
              >
                <QuestionMultipleItem
                  v-if="content.meta.is_multi"
                  is-vote
                  :meta="content.meta"
                  :result="content.result"
                  :can-be-skipped="content.canBeSkipped"
                  :is-skipped="content.isSkipped"
                  :disabled="loading===content.id"
                  :loading="loading===content.id"
                  :data-test="`level-question-multiple-vote-item-${content.id}`"
                  @submit="submit($event, content)"
                  @skip="skip(content.id)"
                />
                <QuestionItem
                  v-else
                  is-vote
                  :meta="content.meta"
                  :result="content.result"
                  :can-be-skipped="content.canBeSkipped"
                  :is-skipped="content.isSkipped"
                  :disabled="loading===content.id"
                  :loading="loading===content.id"
                  :data-test="`level-question-vote-item-${content.id}`"
                  @submit="submit($event, content)"
                  @skip="skip(content.id)"
                />
              </CardWrapper>

              <CardWrapper
                v-else-if="
                  (content.type === $options.ITEM_TYPES.ONE_WORD) || (content.type === $options.ITEM_TYPES.QR_CODE)
                "
                class="pa-5"
                :status="content.itemStatus"
              >
                <WordItem
                  :is-q-r="content.type === $options.ITEM_TYPES.QR_CODE"
                  :meta="content.meta"
                  :result="content.result"
                  :can-be-skipped="content.canBeSkipped"
                  :is-skipped="content.isSkipped"
                  :disabled="loading===content.id"
                  :loading="loading===content.id"
                  :data-test="`level-word-item-${content.id}`"
                  @submit="submit($event, content)"
                  @skip="skip(content.id)"
                />
              </CardWrapper>

              <MapItem
                v-else-if="content.type === $options.ITEM_TYPES.MAP"
                :meta="content.meta"
                :result="content.result"
                :can-be-skipped="content.canBeSkipped"
                :is-skipped="content.isSkipped"
                :disabled="loading===content.id"
                :loading="loading===content.id"
                :data-test="`level-map-item-${content.id}`"
                @submit="submit($event, content)"
                @skip="skip(content.id)"
              />

              <VideoItem
                v-else-if="content.type === $options.ITEM_TYPES.VIDEO"
                :meta="content.meta"
                :result="!!content.result"
                :data-test="`level-video-item-${content.id}`"
                @submit="submit(true, content)"
              />

              <CardWrapper
                v-else-if="content.type === $options.ITEM_TYPES.ENTER_STRING"
                class="pa-5"
                :status="content.itemStatus"
              >
                <TextAnswerItem
                  :meta="content.meta"
                  :result="content.result"
                  :can-be-skipped="content.canBeSkipped"
                  :is-skipped="content.isSkipped"
                  :disabled="loading===content.id"
                  :loading="loading===content.id"
                  :task-completed="task.isCompleted"
                  :data-test="`level-text-answer-item-${content.id}`"
                  @submit="submit($event, content)"
                  @skip="skip(content.id)"
                />
              </CardWrapper>
              <div
                v-else-if="content.type === $options.ITEM_TYPES.IMAGE"
              >
                <ImageTaskItem
                  :data-test="`level-image-task-item-${content.id}`"
                  :meta="content.meta"
                />
              </div>
              <CardWrapper
                v-else-if="content.type === $options.ITEM_TYPES.OFFER"
                class="pa-5"
              >
                <OfferItem
                  :meta="content.meta"
                  :data-test="`level-offer-item-${content.id}`"
                />
              </CardWrapper>
            </VCol>
          </VRow>
          <VRow v-if="task && !task.isCompleted">
            <VCol
              :cols="12"
              :md="$options.TASK_COLS"
            >
              <TTBtn
                block
                large
                :loading="completeBtnLoading"
                class="mb-5"
                :disabled="!canBeCompleted"
                data-test-label="level-view-btn-task-complete"
                @click="completeTask"
              >
                {{ $t('common.complete') }} {{ $t('common.task') }}
              </TTBtn>
            </VCol>
          </VRow>
        </VCol>
      </VRow>
      <TTBtn
        fixed
        color="tt-secondary"
        bottom
        class="mb-12"
        right
        fab
        data-test="button-show-ask-question-form"
        @click="handleClickShowAskQuestionForm"
      >
        <VIcon>
          fas fa-question
        </VIcon>
      </TTBtn>
    </VContainer>
    <DialogWrapper v-model="showAskQuestionForm">
      <AskQuestionForm
        :task-id="taskId"
        @close="handleCloseAskQuestionForm"
      />
    </DialogWrapper>
    <TaskAsideRight
      v-model="showTaskPanel"
      :level-id="levelId"
      :task-id="taskId"
    />
  </div>
</template>

<script>
import { ITEM_TYPES, REQUIRED, TASK } from '@/helpers/constants';
import { TASK_COLS } from '@/components/task/constants';
import {
  MERGE_LEVELS,
} from '@/plugins/vuex/mutationTypes';

import getResultRequest from '@/helpers/getResultRequest';
import { generateFlatItem } from '@/helpers/generateFlatItem';

import * as snamiApi from '@/services/api/snami';

import ChipDuration from '@/components/chips/ChipDuration.vue';
import ChipBonus from '@/components/chips/ChipBonus.vue';
import ChipStatus from '@/components/chips/ChipStatus.vue';
import HTMLItem from '@/components/items/HTMLItem.vue';
import RateItem from '@/components/items/RateItem.vue';
import DocumentItem from '@/components/items/DocumentItem.vue';
import YesOrNoItem from '@/components/items/YesOrNoItem.vue';
import ContactsItem from '@/components/items/ContactsItem.vue';
import MapItem from '@/components/items/MapItem.vue';
import VideoItem from '@/components/items/VideoItem.vue';
import QuestionItem from '@/components/items/QuestionItem.vue';
import QuestionMultipleItem from '@/components/items/QuestionMultipleItem.vue';
import WordItem from '@/components/items/WordItem.vue';
import ImageFormItem from '@/components/items/ImageFormItem.vue';
import TextAnswerItem from '@/components/items/TextAnswerItem.vue';
import ImageTaskItem from '@/components/items/ImageTaskItem.vue';
import OfferItem from '@/components/items/OfferItem.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { SET_HAPPY_STATE_STATES_ACTION, SET_LEVEL_LIST_ACTION, SKIP_ITEM_ACTION } from '@/plugins/vuex/actionTypes';
import AskQuestion from '@/components/shared/AskQuestion.vue';
import CardWrapper from '@/components/cards/CardWrapper.vue';
import AppBarMobile from '@/components/app/AppBarMobile.vue';
import BackButton from '@/components/shared/BackButton.vue';
import TaskAsideRight from '@/components/task/TaskAsideRight.vue';
import AskQuestionForm from '@/components/level/AskQuestionForm.vue';
import DialogWrapper from '@/components/shared/DialogWrapper.vue';

export default {
  name: 'LevelView',

  REQUIRED,
  TASK_COLS,
  ITEM_TYPES,

  components: {
    DialogWrapper,
    AskQuestionForm,
    TaskAsideRight,
    BackButton,
    AppBarMobile,
    CardWrapper,
    AskQuestion,
    ImageFormItem,
    OfferItem,
    ImageTaskItem,
    TextAnswerItem,
    WordItem,
    QuestionMultipleItem,
    QuestionItem,
    VideoItem,
    MapItem,
    ContactsItem,
    YesOrNoItem,
    DocumentItem,
    HTMLItem,
    ChipBonus,
    ChipDuration,
    ChipStatus,
    RateItem,
  },

  inject: ['Names'],

  props: {
    levelId: {
      type: [String, Number],
      required: true,
    },

    taskId: {
      type: [String, Number],
      default: null,
    },
  },

  data() {
    return {
      loading: null,
      errors: [],
      completeBtnLoading: false,
      showTaskPanel: false,
      showAskQuestionForm: false,
    };
  },

  computed: {
    ...mapGetters('levels', { skipped: 'skippedTaskItem', levelById: 'levelById' }),
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    canBeCompleted() {
      const { task } = this;
      if (!task || !task.content || task.isCompleted) {
        return false;
      }
      return task.content.every((item) => item.isSkipped || item.isCompleted
        || !item.canHasResult || (item.canBeSkipped && item.type === ITEM_TYPES.DOCUMENT));
    },

    currentLevelId() {
      return Number(this.levelId);
    },

    currentTaskId() {
      return Number(this.taskId);
    },
    level() {
      return this.levelById(this.currentLevelId);
    },

    task() {
      if (this.taskId) {
        const { currentTaskId: taskId, currentLevelId: levelId } = this;
        const currentTask = this.level.tasks.find((task) => task.id === taskId);
        if (currentTask && currentTask.content) {
          const { isCompleted: taskCompleted } = currentTask;
          const content = currentTask.content.map(
            (taskItem) => generateFlatItem(
              {
                skippedArray: this.skipped,
                levelId,
                taskId,
                taskCompleted,
                taskItem,
              },
            ),
          );
          return { ...currentTask, content };
        }
      }
      return null;
    },
  },

  watch: {
    taskId: {
      handler() {
        this.checkTaskId();
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions('levels', { setLevelListAction: SET_LEVEL_LIST_ACTION, skipItemAction: SKIP_ITEM_ACTION }),
    ...mapActions('happyState', { setHappyStateStatesAction: SET_HAPPY_STATE_STATES_ACTION }),
    ...mapMutations('levels', { mergeLevels: MERGE_LEVELS }),
    handleClickTaskPanel() {
      this.showTaskPanel = !this.showTaskPanel;
    },
    handleClickShowAskQuestionForm() {
      this.showAskQuestionForm = true;
    },
    handleCloseAskQuestionForm() {
      this.showAskQuestionForm = false;
    },
    async completeTask() {
      const { taskId } = this;
      this.loading = taskId;
      try {
        this.completeBtnLoading = true;
        const { data: { happyState } } = await snamiApi.taskComplete({
          data: {
            checkOnly: false,
            taskId,
            entityType: TASK,
          },
        });
        this.setHappyStateStatesAction({ states: happyState });
        const { data: levels } = await snamiApi.levelListV2Get();
        this.setLevelListAction(levels);
        this.checkTaskId(true);
      } catch (e) {
        // TODO Обработать ошибки
        console.warn(e);
      } finally {
        this.completeBtnLoading = false;
      }
    },

    checkTaskId(nextTask = false) {
      this.loading = null;
      const { levelId, taskId, task } = this;
      if (!task || !taskId || nextTask) {
        let uncompleted = null;
        if (nextTask) {
          const currentIndex = this.level.tasks.findIndex((item) => item.id === task.id);
          const nextIndex = (currentIndex + 1) % this.level.tasks.length;
          if (nextIndex) {
            uncompleted = this.level.tasks.slice(nextIndex)
              .find(({ isCompleted }) => !isCompleted);
          }
        }
        if (!uncompleted) {
          uncompleted = this.level.tasks.find(({ isCompleted }) => !isCompleted);
        }
        if (nextTask && !uncompleted) {
          this.$router.push({ name: this.Names.R_APP_LEVELS });
        } else {
          this.$router.replace({
            name: this.Names.R_APP_LEVEL_VIEW,
            params: {
              levelId,
              taskId: String(uncompleted?.id || this.level.tasks[0]?.id),
            },
          });
        }
      } else {
        this.scrollToUncompletedItem();
      }
    },

    scrollToUncompletedItem() {
      const uncompletedItem = this.task.content.find(({ isSkipped }) => !isSkipped);
      if (uncompletedItem && uncompletedItem !== this.task.content[0]) {
        this.goTo({
          target: `#item-${uncompletedItem.id}`, options: { offset: -this.$vuetify.application.top },
        });
      } else {
        this.goTo({ target: 0 });
      }
    },

    goTo({ target, options }) {
      this.$nextTick(() => {
        this.$vuetify.goTo(target, options);
      });
    },

    skip(id) {
      this.skipItemAction(id);
    },

    async submit(answer, item, callback = null) {
      this.clearErrors(item.id);
      if (item.type === ITEM_TYPES.ENTER_STRING && item.canBeSkipped && !answer) {
        this.skip(item.id);
        return;
      }
      try {
        this.loading = item.id;
        const data = getResultRequest(item, answer);
        const { data: result } = await snamiApi.taskResultUpdate({ data });
        this.mergeLevels(result);
      } catch (e) {
        const dataError = e.response?.data?.error;
        if (dataError) {
          this.errors.push({ id: item.id, dataError });
        }
      } finally {
        if (callback) {
          callback();
        }
        this.loading = null;
      }
    },
    clearErrors(itemId) {
      this.errors = this.errors.filter((errorItem) => errorItem.id !== itemId);
    },
    getErrorMessages(itemId) {
      if (!itemId) {
        return [];
      }
      const preparedErrors = this.errors.filter((error) => error.id === itemId)
        .map((errorItem) => errorItem.dataError.data
          .map((errorData) => errorData.value)).flat();
      return [...new Set(preparedErrors)];
    },
  },
};
</script>
