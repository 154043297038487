<template>
  <VContainer
    class="pa-0"
    data-test="ask-question-card"
  >
    <VRow
      justify="space-between"
      align="center"
    >
      <VCol cols="auto">
        <div
          data-test="ask-question-title"
          class="tt-text-title-2"
        >
          {{ $t('ask') }} {{ $t('question') }}
        </div>
      </VCol>

      <VCol cols="auto">
        <TTBtn
          icon
          class="ask-question-btn-close"
          data-test-label="ask-question-button-close"
          @click="closeQuestionForm"
        >
          <VIcon color="tt-light-mono-100">
            fal fa-times
          </VIcon>
        </TTBtn>
      </VCol>
    </VRow>
    <VRow>
      <VCol>
        <TTTextarea
          ref="question"
          v-model="question"
          :maxlength="false"
          :disabled="loading"
          class="ask-question-text mb-2"
          autofocus
          data-test-label="ask-question-textarea"
        />

        <div
          data-test="ask-question-text-validate"
          class="tt-text-body-2 tt-light-mono-46--text"
        >
          {{ $t('validators.not') }}
          {{ $t('validators.less') }}
          {{ $tc('validators.char', $options.QUESTION_MIN_LENGTH) }}
        </div>
      </VCol>
    </VRow>
    <VRow>
      <VCol
        cols="6"
      >
        <TTBtn
          class="ask-question-dialog-btn"
          color="tt-secondary"
          large
          block
          data-test-label="ask-question-btn-cancel"
          @click="closeQuestionForm"
        >
          {{ $t('cancel') }}
        </TTBtn>
      </VCol>
      <VCol
        cols="6"
      >
        <TTBtn
          class="ask-question-dialog-btn"
          :disabled="question.length < $options.QUESTION_MIN_LENGTH"
          :loading="loading"
          primary
          large
          block
          data-test-label="ask-question-btn-submit"
          @click="sendQuestion"
        >
          {{ $t('items.text_answer.send') }}
        </TTBtn>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';

import { toggleSnack } from '@/plugins/vuex/mutationTypes';
import * as snamiApi from '@/services/api/snami';
import { SET_HAPPY_STATE_STATES_ACTION } from '@/plugins/vuex/actionTypes';
import { entityType } from '@/helpers/constants';

export default {
  name: 'AskQuestionForm',

  inject: ['Names'],

  props: {
    taskId: {
      type: [String, Number],
      default: null,
    },
    entityType: {
      type: [String, Number],
      validator: (v) => Object.values(entityType)
        .includes(v),
      default: entityType.NOT_SET,
    },
  },

  data() {
    return {
      question: '',
      loading: false,
    };
  },

  QUESTION_MIN_LENGTH: 10,
  methods: {
    ...mapMutations([toggleSnack]),
    ...mapActions('happyState', { setHappyStateStatesAction: SET_HAPPY_STATE_STATES_ACTION }),
    closeQuestionForm() {
      this.question = '';
      this.onCloseClick();
    },
    onCloseClick() {
      this.$emit('close');
    },
    // Вынести метод за пределы компонента, здесь оставить только emit события?
    async sendQuestion() {
      try {
        this.loading = true;
        const data = {
          entityType: this.entityType,
          problem: this.question,
          taskId: this.taskId,
        };
        const { data: { happyState } } = await snamiApi.appealCreate({ data });
        this.setHappyStateStatesAction({ states: happyState });
        this.toggleSnack({
          message: this.$t('question_sent'),
          status: 'success',
        });
        this.closeQuestionForm();
      } catch (e) {
        const errorMessage = e.response?.error?.message || this.$t('request_error');

        this.toggleSnack({
          message: errorMessage,
          status: 'error',
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
